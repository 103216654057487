<template>
    <div>
      <b-collapse
            aria-id="contentIdForA11y2"
            class=""
            animation="slide"
            v-model="isOpen"
        >
            <template #trigger>
                <div
                    class="header"
                    role="button"
                    aria-controls="contentIdForA11y2"
                >
                    <p class="is-size-4 is-pulled-left title-strong">
                      <v-icon v-if="isOpen" class="icon" name="chevron-down"/>
                      <v-icon v-else class="icon" name="chevron-right"/>
                      Survey Definition
                    </p>
                    <p class="is-size-5 is-pulled-right title-strong">
                        <span>
                            <v-icon class="icon" :name="headingIcon"/> {{headingText}}
                        </span>
                    </p>
                </div>
            </template>

        <div v-if="isLoading">
            <v-icon name="spinner" spin/>
        </div>
        <div v-else>
          <div class="columns" style="width:100%">
              <div class="column is-two-thirds">
                  <b-message :type="messageType" has-icon> <span v-html="messageText"></span> </b-message>
              </div>

              <div class="column">
                <div v-if="isProcessing">
                    <v-icon name="spinner" spin/> Processing...
                </div>
                <div v-else-if="surveyId" class="button-container is-pulled-right">
                    <button class="button is-primary is-outlined" v-if="!datamap" @click="showDatamapFileSelector">Add Survey Definition File</button>
                    <button class="button is-primary is-outlined" v-if="datamap && $can('download','SurveyFiles')" @click="downloadDatamap">Download</button>
                    <button class="button is-primary is-outlined" v-if="datamap" @click="showConfirmDeleteModal">Remove Survey Definition</button>
                </div>
              </div>
          </div>

          <display-datamap v-if="isValidDatamap && !isProcessing" :datamap="processedDatamap" style="margin-top:10px"></display-datamap>

          <div style="width:80%; margin:auto;" v-if="isInvalidDatamap && !isProcessing">
              <b-message title="Invalid Survey Definition" type="is-danger" has-icon :closable="false" v-if="datamapId && errorMessage">
                  <p>Survey Definition file (<b>{{datamapName}}</b>) processing failed with the following error:</p>
                  {{ errorMessage }}
              </b-message>
              <b-message class="has-text-left" title="Validation Errors" type="is-danger" has-icon :closable="false"
                  v-if="datamapId && validationErrorMessages && validationErrorMessages.length > 0">
                  <p>Survey Definition (<b>{{datamapName}}</b>) processing failed with the following validation errors:</p>
                  <ul style="list-style:inside">
                    <li v-for="message in validationErrorMessages" :key="message">
                      {{ message }}
                    </li>
                  </ul>
              </b-message>
          </div>
        </div>
        <div class="columns">
            <div class="column is-one-half"> </div>
            <!-- save action -->
            <div class="column">

              <div class="button-container is-pulled-right">
                <button class="button is-outlined is-success"
                  @click="saveChangesToProcessedDatamap"
                  :disabled="isSaving||isLoading||isProcessing||isDownloading||!hasChanges">
                    Save
                </button>
                <button class="button is-success" @click="next" :disabled="!isValidDatamap || hasChanges">Next</button>
              </div>
               <b-tag v-if="hasChanges&&!(isSaving||isLoading||isProcessing||isDownloading)"
                      type="is-danger" size="is-medium"
                      class="is-pulled-right has-margin-top-right">
                    Unsaved changes detected, please save before continuing.
               </b-tag>
            </div>
        </div>

      </b-collapse>
    </div>
</template>

<script>
import _ from 'lodash';
import { EventBus } from '@/event-bus';
import { saveAs } from 'file-saver';

import datamapService from '@/services/datamap-service.js';
import DatamapFileSelector from '@/components/DatamapFileSelector.vue';
import DisplayDatamap from '@/components/DisplayDatamap.vue';

export default {
    name: 'Datamap',
    components: {
        DisplayDatamap
    },
    props: {
        surveyId: {
            type: Number,
            required: false
        }
    },
    data () {
        return {
            errorMessage: '',
            isLoading: true,
            isSaving: false,
            datamap: null,
            isOpen: false,
            isDownloading: false,
            isProcessing: false,
            processedDatamap: null,
            validationErrorMessages: [],
            headingIcon: 'exclamation-circle',
            headingText: 'Survey Detail required',
            messageType: 'is-info',
            messageText: 'Please complete the Survey Detail section before uploading a definition file.',
            originalContent: undefined
        };
    },
    async created () {
        EventBus.$on('surveyDetailNext', () => {
            this.isOpen = true;
        });

        // Set the global state of this component to incomplete as default.
        this.updateStateInVueXStore(false);

        if (this.surveyId) {
            this.setNoDatamapState();
            this.isOpen = true; // un-collapse/open this section if we've got a surveyid set - either we're working on this section, or it's valid
            try {
                const response = await datamapService.getDatamapForSurvey(this.surveyId);
                this.datamap = response.data;
                await this.processDatamap(this.datamap.id);
            } catch (error) {
                console.log('This survey does not contain any survey definition.');
            }
        }
        this.setOriginalContent();
        this.isLoading = false;
    },
    methods: {
        async save (datamap) {
            this.isSaving = true;
            try {
                // create datamap
                const formData = new FormData();
                formData.append('File', datamap.file);
                formData.append('SurveyId', this.surveyId);
                formData.append('Name', datamap.name);
                formData.append('Provider', datamap.providerName);
                const response = await datamapService.addDatamap(formData);
                this.datamap = response.data;
                await this.processDatamap(this.datamap.id);
            } catch (error) {
                const errorResponse = (await error).response;
                if (
                    errorResponse &&
                    errorResponse.data &&
                    errorResponse.data.message
                ) {
                    this.errorMessage = errorResponse.data.message;
                } else {
                    this.errorMessage = error.message;
                }
            } finally {
                this.isSaving = false;
                EventBus.$emit('surveyDefinitionChanged');
            }
        },
        showDatamapFileSelector () {
            this.$buefy.modal.open({
                parent: this,
                component: DatamapFileSelector,
                hasModalCard: true,
                events: {
                    addFile: async (datamap) => this.save(datamap)
                }
            });
        },
        showConfirmDeleteModal () {
            this.$buefy.dialog.confirm({
                title: 'Delete Survey Definition',
                message: this.$sanitize(`This will permanently delete the uploaded Survey Definition <b>${this.datamap.name}</b>.<br>Do you wish to continue?`),
                type: 'is-danger',
                confirmText: 'Delete',
                cancelText: 'Cancel',
                hasIcon: true,
                onConfirm: () => this.deleteDatamap()
            });
        },
        async deleteDatamap () {
            if (this.datamap && this.datamap.id) {
                try {
                    await datamapService.deleteDatamap(this.datamap.id);
                    this.datamap = null;
                    this.setNoDatamapState();
                    await this.processDatamap(null);
                } catch (error) {
                    this.errorMessage = error.message;
                } finally {
                    EventBus.$emit('surveyDefinitionChanged');
                }
            }
        },
        async downloadDatamap () {
            if (this.datamap && this.datamap.id) {
                try {
                    this.isDownloading = true;
                    const response = await datamapService.downloadDatamap(this.datamap.id);
                    saveAs(response.data, this.datamap.name);
                } catch (error) {
                    this.errorMessage = error.message;
                } finally {
                    this.isDownloading = false;
                }
            }
        },
        async processDatamap (id) {
            if (id) {
                this.isProcessing = true;
                this.setProcessingState();
                try {
                    // process the datamap
                    const response = await datamapService.processDatamap(id);
                    this.processedDatamap = response.data;
                    this.setOriginalContent();
                    if (this.processedDatamap && !_.isEmpty(this.processedDatamap.validationErrorMessages)) {
                        this.errorMessage = '';
                        this.validationErrorMessages = this.processedDatamap.validationErrorMessages;
                        this.setInvalidDatamapState();
                    } else {
                        this.errorMessage = '';
                        this.validationErrorMessages = [];
                        this.setValidDatamapState();
                    }

                    if (this.processedDatamap) {
                        this.processedDatamap.surveyDatamap.audiences = this.processedDatamap.surveyDatamap.audiences.map(aud => ({
                            ...aud,
                            isEdit: false
                        }));
                    }
                } catch (error) {
                    const errorResponse = (await error).response;
                    if (
                        errorResponse &&
                        errorResponse.data &&
                        errorResponse.data.message
                    ) {
                        this.errorMessage = errorResponse.data.message;
                    } else {
                        this.errorMessage = error.message;
                    }
                    this.setInvalidDatamapState();
                } finally {
                    this.isProcessing = false;
                }
            } else {
                this.processedDatamap = null;
                this.setOriginalContent();
                this.errorMessage = '';
                this.validationErrorMessages = [];
            }
        },
        setNoDatamapState () {
            this.messageType = 'is-info';
            this.messageText = 'Please upload a survey definition file.';
            this.headingIcon = 'exclamation-circle';
            this.headingText = 'Incomplete';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setProcessingState () {
            this.messageType = 'is-info';
            this.messageText = 'Processing uploaded survey definition file.';
            this.headingIcon = 'exclamation-circle';
            this.headingText = 'Processing';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setInvalidDatamapState () {
            this.messageType = 'is-danger';
            this.messageText = this.$sanitize(`Error while processing survey definition file  <b>${this.datamap.name}</b>.`);
            this.headingText = 'Invalid Survey Definition';
            this.headingIcon = 'exclamation-triangle';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setValidDatamapState () {
            this.messageType = 'is-success';
            this.messageText = this.$sanitize(`Successfully processed survey definition file  <b>${this.datamap.name}</b>.
                <br />Please review Audiences, Touchpoints and Brands before proceeding.`);
            this.headingText = 'Valid Survey Definition';
            this.headingIcon = 'check-circle';
            // Set the global state of this component to complete.
            this.updateStateInVueXStore(true);
        },
        setValidDatamapWithChangesState () {
            this.messageType = 'is-success';
            this.messageText = this.$sanitize(`Successfully processed survey definition file  <b>${this.datamap.name}</b>.`);
            this.headingText = 'Valid Survey Definition with Unsaved Changes';
            this.headingIcon = 'exclamation-circle';
            // Don't change the global state of this component, changes don't count until they're saved
        },
        next () {
            EventBus.$emit('surveyDefinitionNext');
            this.isOpen = false;
        },
        updateStateInVueXStore (isComplete) {
            // Set the global state of this component.
            this.$store.commit('setIsSurveyDefinitionComplete', isComplete);
        },
        async saveChangesToProcessedDatamap () {
            this.isSaving = true;
            try {
                this.datamap.processedDatamapJson = JSON.stringify(this.getDataWithoutUIOnlyFieldsFrom(this.processedDatamap));
                await datamapService.updateDatamapFields(this.datamap);

                this.setOriginalContent();
                this.setValidDatamapState();
            } catch (error) {
                const errorResponse = (await error).response;
                if (
                    errorResponse &&
                    errorResponse.data &&
                    errorResponse.data.message
                ) {
                    this.errorMessage = errorResponse.data.message;
                } else {
                    this.errorMessage = error.message;
                }
            } finally {
                this.isSaving = false;
                EventBus.$emit('surveyDefinitionChanged');
            }
        },
        serializedContent () {
            const content = this.getDataWithoutUIOnlyFieldsFrom(this.processedDatamap);
            // Pick out the properties we care about from the model
            return _.pick(content, ['surveyDatamap']);
        },
        setOriginalContent () {
            this.originalContent = this.serializedContent();
        },
        getDataWithoutUIOnlyFieldsFrom (processedDatamap) {
            const contentWithoutUIOnlyFields = _.cloneDeep(processedDatamap);
            if (!contentWithoutUIOnlyFields) return contentWithoutUIOnlyFields;
            contentWithoutUIOnlyFields.surveyDatamap.audiences = contentWithoutUIOnlyFields.surveyDatamap.audiences.map(aud => {
                delete aud.isEdit;
                return aud;
            });
            return contentWithoutUIOnlyFields;
        }
    },
    computed: {
        datamapId () {
            return this.datamap?.id;
        },
        datamapName () {
            return this.datamap?.name;
        },
        isValidDatamap () {
            if (this.processedDatamap &&
                this.processedDatamap.surveyDatamap &&
                _.isEmpty(this.processedDatamap.validationErrorMessages) &&
                _.isEmpty(this.errorMessage) &&
                _.isEmpty(this.validationErrorMessages)) {
                return true;
            }
            return false;
        },
        isInvalidDatamap () {
            if (!_.isEmpty(this.errorMessage) || !_.isEmpty(this.validationErrorMessages)) {
                return true;
            }
            return false;
        },
        hasChanges () {
            const currentContent = this.serializedContent();
            return !_.isEqual(this.originalContent, currentContent);
        }
    },
    watch: {
        hasChanges: function (value) {
            if (value && this.isValidDatamap) {
                this.setValidDatamapWithChangesState();
            } else if (this.isValidDatamap) {
                this.setValidDatamapState();
            }
            // otherwise stay in whatever state we were already in
        }
    }
};
</script>

<style scoped lang="scss">
.button-container {
  margin-top:10px;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}

div.header {
  height: 3em;
  padding-top: 0.5em;
}
.title-strong {
  font-weight: 600;
}
.button-container {
  margin-top:10px;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.message {
  // override inherited text alignment for the b-message box
  text-align: left;
}
.tag.has-margin-top-right {
  margin-top: 1em;
  margin-right: 1em;
}
</style>
